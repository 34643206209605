import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Flex,
  Button as ChakraButton,
  Group,
  IconButton,
  Portal,
  HStack,
  Box,
  Table,
  Icon,
} from '@chakra-ui/react';

import { DatePicker, Popconfirm, Select, TimePicker } from 'antd';
import { MdCheckCircleOutline, MdDelete, MdEdit, MdWarningAmber } from 'react-icons/md';
import { AiFillSave } from 'react-icons/ai';
import { IoMdTrash } from 'react-icons/io';
import { CgUndo } from 'react-icons/cg';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import { FaUndo } from 'react-icons/fa';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { contains } from 'underscore';

import Constants from '../../../Constants';
import { isOrderCompleted } from '../helpers';
import EditReleaseModal from './EditReleaseModal';
import SelectField from '../../basic/SelectField';
import { ReleasableOrderContext } from './contexts';
import {
  PopoverRoot,
  PopoverCloseTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverFooter,
  PopoverBody,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Checkbox } from '@/components/ui/checkbox';
import { Tooltip } from '@/components/ui/tooltip';
import './GrinderTable.scss';
import { toaster } from '@/components/ui/toaster';

const { Option } = Select;

const GrinderTableActionPopover = ({ isOpen, triggerComponent, title, onClose, onConfirm }) => {
  return (
    <PopoverRoot open={isOpen} placement="bottom-end">
      <PopoverTrigger>{triggerComponent}</PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <HStack gap="8px" alignItems="flex-start" paddingTop="9px">
              <Icon marginTop="5px">
                <MdWarningAmber size="24px" color="neutral.5" />
              </Icon>
              <Text as="p" fontSize="16px">
                {title}
              </Text>
              <PopoverCloseTrigger position="inherit" onClick={onClose} />
            </HStack>
          </PopoverBody>
          <PopoverFooter>
            <HStack justifyContent="flex-end" gap="8px">
              <ChakraButton variant="outline" height="25px" width="80px" fontWeight="black" onClick={onClose}>
                Cancel
              </ChakraButton>
              <ChakraButton
                colorScheme="actionPrimary"
                height="25px"
                width="56px"
                fontWeight="black"
                onClick={onConfirm}
              >
                OK
              </ChakraButton>
            </HStack>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </PopoverRoot>
  );
};
GrinderTableActionPopover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  triggerComponent: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
class GrinderTable extends Component {
  static contextType = ReleasableOrderContext;

  state = {
    actionType: null,
    showEditReleaseModal: false,
    selectedOrder: '',
    previewOrders: {
      url: null,
      name: null,
    },
    previousOrders: [...this.props.orders],
    sortOrder: null,
    sortTooltipMessage: 'Click to sort ascending',
    sortedOrders: null,
    openedPopoverOrderId: null,
  };

  componentDidMount() {
    this.setState({ previousOrders: [...this.props.orders] });
  }

  componentDidUpdate(prevProps, prevState) {
    const { previewOrders } = this.props;
    if (previewOrders && prevState.previewOrders !== previewOrders) {
      this.setState({ previewOrders });
    }
  }

  renderInput = props => {
    return (
      <div>
        <input {...props} />
      </div>
    );
  };

  getTableColumns = () => [
    {
      title: this.props.internalPoLabel,
      dataIndex: 'internal_po_number',
      key: 'internal_po_number',
      render: (text, order) => (
        <div className="grinder-table-first-col" style={{ width: 'max-content' }}>
          <MdCheckCircleOutline
            className={
              isOrderCompleted(this.props.ordersMap[order.id])
                ? 'grinder-table__ordered-status-true'
                : 'grinder-table__ordered-status-false'
            }
          />
          {order.internal_po_number}
        </div>
      ),
    },
    {
      title: 'Grinder PO#',
      dataIndex: 'grinder_po_number',
      key: 'grinder_po_number',
    },
    {
      title: 'Coldstore',
      dataIndex: 'coldstore',
      render: (text, order) => (order.coldstore_details ? order.coldstore_details.cold_store.location_name : ''),
    },
    {
      title: 'Lot#',
      dataIndex: 'lot_numbers',
      render: (text, order) =>
        order.coldstore_details && order.coldstore_details.lot_numbers
          ? order.coldstore_details.lot_numbers.join(', ')
          : '',
    },
    {
      title: 'Case Count',
      dataIndex: 'carton_count',
      render: (text, order) => order.lines.map(line => line.carton_count || 0).reduce((a, b) => a + b, 0),
    },
    {
      title: 'Used By Date',
      dataIndex: 'used_by_date',
      render: (text, order) =>
        order.earliest_used_by_date ? moment(order.earliest_used_by_date, 'YYYY-MM-DD').format('MM-DD-YYYY') : '',
    },
    {
      title: 'Pickup Date',
      dataIndex: 'pickup_date',
      key: 'pickup_date',
      render: (text, order) => (
        <DatePicker
          className="grinder-table__input_control"
          disabled={!this.props.isEditable}
          id="pickup_date_picker"
          renderInput={props => this.renderInput({ ...props, disabled: this.props.isEditable })}
          value={
            this.props.ordersMap[order.id].shipment_date &&
            moment(this.props.ordersMap[order.id].shipment_date, 'YYYY-MM-DD')
          }
          onChange={value => {
            try {
              const deliveryDate = moment(this.props.ordersMap[order.id].delivery_date, 'YYYY-MM-DD') || null;
              if (deliveryDate && value.isAfter(deliveryDate, 'date')) {
                toaster.create({
                  description: 'Pickup date cannot be after delivery date',
                  type: 'error',
                });
                this.props.handleValueChange(order.id, 'shipment_date', null);
              } else {
                this.props.handleValueChange(order.id, 'shipment_date', value.format('YYYY-MM-DD'));
              }
            } catch (e) {
              this.props.handleValueChange(order.id, 'shipment_date', null);
            }
          }}
          size="default"
          format="MM-DD-YYYY"
        />
      ),
    },
    {
      title: 'Delivery Date',
      dataIndex: 'delivery_date',
      key: 'delivery_date',
      sorter: (d1, d2) => moment(d1.delivery_date) - moment(d2.delivery_date),
      render: (text, order) => (
        <DatePicker
          className="grinder-table__input_control"
          disabled={!this.props.isEditable}
          id="delivery_date_picker"
          renderInput={props => this.renderInput({ ...props, disabled: this.props.isEditable })}
          value={moment(this.props.ordersMap[order.id].delivery_date, 'YYYY-MM-DD') || null}
          onChange={value => {
            const shipmentDate = moment(this.props.ordersMap[order.id].shipment_date, 'YYYY-MM-DD') || null;
            if (shipmentDate && value.isBefore(shipmentDate, 'date')) {
              toaster.create({
                description: 'Delivery date cannot be before pickup date.',
                type: 'error',
              });
            } else {
              this.props.handleValueChange(order.id, 'delivery_date', value && value.format('YYYY-MM-DD'));
            }
          }}
          size="default"
          format="MM-DD-YYYY"
        />
      ),
    },
    {
      title: 'Delivery Time',
      dataIndex: 'delivery_time',
      key: 'delivery_time',
      render: (text, order) => (
        <div style={{ width: 'max-content' }}>
          <TimePicker
            use12Hours
            disabled={this.props.ordersMap[order.id].null_delivery_time === true || this.props.isEditable}
            format="h:mm a"
            value={
              this.props.ordersMap[order.id].scheduled_delivery_time &&
              moment(this.props.ordersMap[order.id].scheduled_delivery_time, 'HH:mm')
            }
            onChange={value =>
              this.props.handleValueChange(order.id, 'scheduled_delivery_time', value && value.format('HH:mm'))
            }
          />
          <Checkbox
            disabled={this.props.isEditable}
            style={{ marginLeft: '5px' }}
            checked={this.props.ordersMap[order.id].null_delivery_time}
            onChange={e => this.props.handleValueChange(order.id, 'null_delivery_time', e.target.checked)}
          >
            N/A
          </Checkbox>
        </div>
      ),
    },
    {
      title: 'Trucking Company',
      dataIndex: 'trucking_company',
      key: 'trucking_company',
      render: (text, order) => (
        <Select
          showSearch
          className="grinder-table__select-dropdown"
          disabled={this.props.isEditable}
          allowClear
          optionFilterProp="children"
          value={this.props.ordersMap[order.id].transporter_id}
          onChange={value => this.props.handleValueChange(order.id, 'transporter_id', value)}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {sortBy(this.props.transporters, 'name').map((transporter, i) => (
            <Option key={i} value={transporter.id}>
              {transporter.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      dataIndex: 'delete',
      title: 'Actions',
      render: (text, order) => {
        return this.props.isEditable === true ? (
          <div>
            {this.props.prevColdstoreStatus === Constants.ORDER_STATUSES.PENDING_COLDSTORE_SCHEDULE ? (
              <Popconfirm
                title="Are you sure you want to move this order back to schedule tab?"
                onConfirm={() => this.moveOneTabBack(order)}
                placement="left"
              >
                <FaUndo
                  title="Go back to schedule tab"
                  onClick={() => {
                    if (window.confirm('Are you sure you want to move this order back to schedule tab?')) {
                      this.moveOneTabBack(order);
                    }
                  }}
                />
              </Popconfirm>
            ) : (
              this.props.prevColdstoreStatus !== Constants.ORDER_STATUSES.COLDSTORE_RELEASED && (
                <Popconfirm
                  title="Are you sure you want to move this order back to inventory?"
                  onConfirm={() => this.clearOrder(order)}
                  placement="left"
                >
                  <MdDelete
                    onClick={() => {
                      if (window.confirm('Are you sure you want to move this order back to schedule tab?')) {
                        this.clearOrder(order);
                      }
                    }}
                  />
                </Popconfirm>
              )
            )}
          </div>
        ) : (
          this.props.onReleaseEditModal === false && (
            <IconButton
              style={{ border: 'none', backgroundColor: 'transparent' }}
              title="Edit & re-send email"
              onClick={() => this.editReleasedOrder(order)}
            >
              <MdEdit />
            </IconButton>
          )
        );
      },
    },
  ];

  clearOrder = order => {
    // clear all fields and move back to inventory
    this.props.patchOrder(order.id, {
      delivery_date: null,
      transporter_id: null,
      scheduled_delivery_time: null,
      shipment_date: null,
      status: Constants.ORDER_STATUSES.COLDSTORE,
    });
  };

  moveOneTabBack = order => {
    /* update the status to previous tab, this moves back current
     order to one step behind without clearing values
     For now, it is only used for moving released to release */
    this.props.patchOrder(
      order.id,
      {
        status: this.props.prevColdstoreStatus,
      },
      'release'
    );
  };

  editReleasedOrder = order => {
    this.setState({ showEditReleaseModal: true, selectedOrder: order });
  };

  handleSort = () => {
    const previousSortOrder = this.state.sortOrder;
    const data = [...this.props.orders];
    if (!previousSortOrder) {
      data.sort((d1, d2) => moment(d1.delivery_date).diff(moment(d2.delivery_date), 'milliseconds'));
      this.setState({ sortOrder: 'asc', sortTooltipMessage: 'Click to sort descending', sortedOrders: data });
    } else if (previousSortOrder === 'asc') {
      data.sort((d1, d2) => moment(d2.delivery_date) - moment(d1.delivery_date));
      this.setState({ sortOrder: 'desc', sortTooltipMessage: 'Click to cancel sorting', sortedOrders: data });
    } else {
      this.setState({ sortOrder: null, sortTooltipMessage: 'Click to sort ascending', sortedOrders: null });
    }
  };

  // Action buttons for grinder table
  renderActionButtons = order => {
    // Rendered on 'Schedule' tab
    if (this.props.prevColdstoreStatus === Constants.ORDER_STATUSES.COLDSTORE) {
      return (
        <GrinderTableActionPopover
          open={this.state.openedPopoverOrderId === order.id}
          triggerComponent={
            <IconButton
              variant="unstyled"
              title="Move to inventory"
              aria-label="Move to inventory"
              onClick={() => this.setState({ openedPopoverOrderId: order.id })}
            >
              <CgUndo title="Go back to inventory tab" />
            </IconButton>
          }
          title="Are you sure you want to move this order back to inventory?"
          onClose={() => this.setState({ openedPopoverOrderId: null })}
          onConfirm={() => this.clearOrder(order)}
        />
      );
    }
    // Rendered on 'Release' tab
    if (this.props.prevColdstoreStatus === Constants.ORDER_STATUSES.PENDING_COLDSTORE_SCHEDULE) {
      return (
        <>
          <IconButton
            variant="unstyled"
            title="Edit release"
            aria-label="Edit release"
            onClick={() => {
              this.props.onEditOrderRow(order, true);
            }}
          >
            <MdEdit />
          </IconButton>
          <GrinderTableActionPopover
            open={this.state.openedPopoverOrderId === order.id}
            triggerComponent={
              <IconButton
                variant="unstyled"
                title="Revert release"
                aria-label="Go back to schedule tab"
                onClick={() => this.setState({ openedPopoverOrderId: order.id })}
              >
                <CgUndo />
              </IconButton>
            }
            title="Are you sure you want to move this order back to schedule tab?"
            onClose={() => this.setState({ openedPopoverOrderId: null })}
            onConfirm={() => this.moveOneTabBack(order)}
          />
        </>
      );
    }
    // Rendered on 'Released' tab
    if (this.props.prevColdstoreStatus === Constants.ORDER_STATUSES.PENDING_COLDSTORE_RELEASE) {
      return (
        <Group gap="7px">
          {this.props.isEditable && this.props.editedOrder?.id === order.id ? (
            <>
              <IconButton
                variant="unstyled"
                title="Revert release"
                aria-label="Revert release"
                onClick={() => {
                  const previousOrder = this.state.previousOrders.find(orderObject => orderObject.id === order.id);
                  if (previousOrder) {
                    const { delivery_date, transporter_id, scheduled_delivery_time, shipment_date } = previousOrder;

                    // revert selected values
                    this.props.patchOrder(
                      order.id,
                      {
                        delivery_date,
                        transporter_id,
                        scheduled_delivery_time,
                        shipment_date,
                      },
                      false
                    );
                    this.props.onToggleEditOrderRow(order, false);
                  }
                }}
              >
                <CgUndo />
              </IconButton>
              <IconButton
                variant="unstyled"
                title="Save release & re-send email"
                aria-label="Save release and resend email"
                onClick={() => {
                  this.setState(
                    {
                      actionType: 'edit',
                    },
                    () => {
                      this.editReleasedOrder(order);
                    }
                  );
                }}
              >
                <AiFillSave />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                variant="unstyled"
                title="Edit release"
                aria-label="Edit release"
                onClick={() => {
                  this.props.onToggleEditOrderRow(order, true);
                }}
              >
                <MdEdit />
              </IconButton>
              <IconButton
                variant="unstyled"
                title="Cancel release"
                aria-label="Cancel release"
                onClick={() => {
                  this.setState(
                    {
                      actionType: 'cancel',
                    },
                    () => {
                      this.editReleasedOrder(order);
                    }
                  );
                }}
              >
                <IoMdTrash />
              </IconButton>
            </>
          )}
        </Group>
      );
    }
  };

  render() {
    const transporterOptions = sortBy(this.props.transporters, 'name').map(transporter => ({
      label: transporter.name,
      value: transporter.id,
    }));

    const ordersMap = { ...this.props.ordersMap };
    const releaseableOrderIds = this.context.map(so => so?.id);
    const selecteOrderIds = this.props.selectedOrders ? Object.keys(this.props.selectedOrders).map(Number) : [];

    const orders = this.state.sortedOrders ? this.state.sortedOrders : this.props.orders;

    return (
      <div className="grinder-table-container">
        <Box overflowX="auto">
          <Table.Root size="sm">
            <Table.Header bg="neutral.8">
              <Table.Row>
                {this.props.prevColdstoreStatus === Constants.ORDER_STATUSES.COLDSTORE && (
                  <Table.ColumnHeader paddingLeft="24px">
                    <Checkbox
                      colorScheme="actionPrimary"
                      onChange={() => this.props.handleMultipleOrdersSelection(orders)}
                      checked={orders.length === selecteOrderIds.length}
                    />
                  </Table.ColumnHeader>
                )}
                {this.getTableColumns().map(col => (
                  <Table.ColumnHeader
                    key={col.dataIndex}
                    textTransform="capitalize"
                    fontSize="12px"
                    fontWeight="bold"
                    color="black"
                    _first={{
                      paddingLeft: '24px',
                    }}
                    _last={{
                      paddingRight: '37px',
                    }}
                    borderTopWidth="1px"
                    borderBottomWidth="1px"
                    borderColor="gray.300"
                  >
                    <Flex justifyContent="center" alignItems="center">
                      {col.key === 'delivery_date' ? (
                        <Tooltip content={this.state.sortTooltipMessage} positioning={{ placement: 'right-end' }}>
                          <ChakraButton
                            onClick={() => this.handleSort()}
                            backgroundColor="inherit"
                            color="inherit"
                            fontWeight="inherit"
                          >
                            <Text as="p">{col.title}</Text>
                            <Box display="flex">
                              <Icon color={this.state.sortOrder === 'desc' ? 'blue' : 'gray.300'}>
                                <GoTriangleDown />
                              </Icon>
                              <Icon color={this.state.sortOrder === 'asc' ? 'blue' : 'gray.300'}>
                                <GoTriangleUp />
                              </Icon>
                            </Box>
                          </ChakraButton>
                        </Tooltip>
                      ) : (
                        <Text as="p">{col.title}</Text>
                      )}
                    </Flex>
                  </Table.ColumnHeader>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {orders.map(order => {
                const isDisabled = !this.props.isEditable;
                return (
                  <Table.Row
                    key={order.id}
                    bg={
                      selecteOrderIds &&
                      this.props.prevColdstoreStatus === Constants.ORDER_STATUSES.COLDSTORE &&
                      ((contains(selecteOrderIds, order.id) &&
                        contains(releaseableOrderIds, order.id) &&
                        'actionSecondary.active20') ||
                        (contains(selecteOrderIds, order.id) &&
                          !contains(releaseableOrderIds, order.id) &&
                          'red.100') ||
                        null)
                    }
                  >
                    {this.props.prevColdstoreStatus === Constants.ORDER_STATUSES.COLDSTORE && (
                      <Table.Cell width="fit-content" paddingLeft="24px">
                        <Checkbox
                          colorScheme="actionPrimary"
                          onChange={e => this.props.handleOrderSelection(order, e.target.checked)}
                          checked={contains(selecteOrderIds, order.id)}
                        />
                      </Table.Cell>
                    )}
                    <Table.Cell
                      _first={{
                        paddingLeft: '24px',
                      }}
                    >
                      {order.internal_po_number}
                    </Table.Cell>
                    <Table.Cell>{order.grinder_po_number}</Table.Cell>
                    <Table.Cell>
                      {order.coldstore_details ? order.coldstore_details.cold_store.location_name : ''}
                    </Table.Cell>
                    <Table.Cell>
                      {order.coldstore_details && order.coldstore_details.lot_numbers
                        ? order.coldstore_details.lot_numbers.join(', ')
                        : ''}
                    </Table.Cell>
                    <Table.Cell>
                      {order.lines.map(line => line.carton_count || 0).reduce((a, b) => a + b, 0)}
                    </Table.Cell>
                    <Table.Cell>
                      {order.earliest_used_by_date
                        ? moment(order.earliest_used_by_date, 'YYYY-MM-DD').format('MM-DD-YYYY')
                        : ''}
                    </Table.Cell>
                    <Table.Cell>
                      <div style={{ width: 'max-content' }}>
                        <DatePicker
                          className="grinder-table__datepicker"
                          disabled={isDisabled}
                          id="pickup_date_picker"
                          renderInput={props => this.renderInput({ ...props, disabled: isDisabled })}
                          value={
                            ordersMap[order.id]?.shipment_date &&
                            moment(ordersMap[order.id].shipment_date, 'YYYY-MM-DD')
                          }
                          onChange={value => {
                            try {
                              const deliveryDate = moment(ordersMap[order.id]?.delivery_date, 'YYYY-MM-DD') || null;
                              if (deliveryDate && value.isAfter(deliveryDate, 'date')) {
                                toaster.create({
                                  description: 'Pickup date cannot be after delivery date',
                                  type: 'error',
                                });
                                this.props.handleValueChange(order.id, 'shipment_date', null);
                              } else {
                                this.props.handleValueChange(order.id, 'shipment_date', value.format('YYYY-MM-DD'));
                              }
                            } catch (e) {
                              this.props.handleValueChange(order.id, 'shipment_date', null);
                            }
                          }}
                          size="default"
                          format="MM-DD-YYYY"
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div style={{ width: 'max-content' }}>
                        <DatePicker
                          className="grinder-table__datepicker"
                          disabled={isDisabled}
                          id="delivery_date_picker"
                          renderInput={props => this.renderInput({ ...props, disabled: isDisabled })}
                          value={
                            ordersMap[order.id]?.delivery_date &&
                            moment(ordersMap[order.id]?.delivery_date, 'YYYY-MM-DD')
                          }
                          onChange={value => {
                            const shipmentDate = moment(ordersMap[order.id]?.shipment_date, 'YYYY-MM-DD') || null;
                            if (shipmentDate && value?.isBefore(shipmentDate, 'date')) {
                              toaster.create({
                                description: 'Delivery date cannot be before pickup date.',
                                type: 'error',
                              });
                            } else {
                              this.props.handleValueChange(
                                order.id,
                                'delivery_date',
                                value && value.format('YYYY-MM-DD')
                              );
                            }
                          }}
                          size="default"
                          format="MM-DD-YYYY"
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <Flex alignItems="center" width="max-content">
                        <TimePicker
                          use12Hours
                          disabled={ordersMap[order.id]?.null_delivery_time === true || isDisabled}
                          format="h:mm a"
                          value={
                            ordersMap[order.id]?.scheduled_delivery_time &&
                            moment(ordersMap[order.id].scheduled_delivery_time, 'HH:mm')
                          }
                          onChange={value =>
                            this.props.handleValueChange(
                              order.id,
                              'scheduled_delivery_time',
                              value && value.format('HH:mm')
                            )
                          }
                          className="grinder-table__input_control"
                        />
                        <Checkbox
                          size="lg"
                          disabled={isDisabled}
                          colorScheme="actionPrimary"
                          marginLeft="8px"
                          marginBottom="0px"
                          checked={ordersMap[order.id]?.null_delivery_time}
                          onChange={e => this.props.handleValueChange(order.id, 'null_delivery_time', e.target.checked)}
                        >
                          <Text as="p" fontSize="md">
                            N/A
                          </Text>
                        </Checkbox>
                      </Flex>
                    </Table.Cell>
                    <Table.Cell>
                      <SelectField
                        className="grinder-table__select-dropdown"
                        isClearable
                        isSearchable
                        closeMenuOnSelect
                        title="Trucking Company"
                        isMulti={false}
                        disabled={isDisabled}
                        placeholder="Select Trucking Company"
                        value={transporterOptions.filter(t => t.value === ordersMap[order.id]?.transporter_id)}
                        onChange={e =>
                          this.props.handleValueChange(order.id, 'transporter_id', e && e.value ? e.value : e)
                        }
                        options={transporterOptions}
                        controlStyles={{
                          borderRadius: '8px',
                          boxShadow: '0px 2px 3px 0px #bfc5d2 inset',
                          height: '28px',
                          minHeight: '28px',
                        }}
                        indicatorsContainerStyles={{
                          height: '28px',
                          minHeight: '28px',
                        }}
                        valueContainerStyles={{
                          height: '28px',
                          minHeight: '28px',
                        }}
                        menuPortalTarget={document.body}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="center" paddingRight="37px">
                      {this.renderActionButtons(order)}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table.Root>
        </Box>
        {this.state.showEditReleaseModal && (
          <EditReleaseModal
            showEditReleaseModal={this.state.showEditReleaseModal}
            selectedOrder={this.props.orders.filter(o => o.id === this.state.selectedOrder.id)}
            patchOrder={this.props.patchOrder}
            transporters={this.props.transporters}
            ordersMap={this.props.ordersMap}
            prevColdstoreStatus={Constants.ORDER_STATUSES.COLDSTORE_RELEASED}
            isEditReleaseModalOpen={showEditReleaseModal => {
              this.setState({ showEditReleaseModal });
            }}
            dispatch={this.props.dispatch}
            token={this.props.token}
            previewOrders={this.state.previewOrders}
            clearOrder={this.clearOrder}
            actionType={this.state.actionType}
            unEditedOrder={this.state.previousOrders.filter(o => o.id === this.state.selectedOrder.id)}
            notifying_action_groups={this.props.notifying_action_groups}
            coldstoreSendEmailError={this.props.coldstoreSendEmailError}
            clearColdStoreEmailModal={this.props.clearColdStoreEmailModal}
            isColdstoreEmailSent={this.props.isColdstoreEmailSent}
            internalPoLabel={this.props.internalPoLabel}
            user={this.props.user}
          />
        )}
      </div>
    );
  }
}

export default GrinderTable;

GrinderTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  ordersMap: PropTypes.shape({}).isRequired,
  transporters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleValueChange: PropTypes.func.isRequired,
  patchOrder: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  handleOrderSelection: PropTypes.func.isRequired,
  handleMultipleOrdersSelection: PropTypes.func.isRequired,
  selectedOrders: PropTypes.shape({}),
  onEditOrderRow: PropTypes.func.isRequired,
  onToggleEditOrderRow: PropTypes.func.isRequired,
  editedOrder: PropTypes.shape({}),
  prevColdstoreStatus: PropTypes.string.isRequired,
  onReleaseEditModal: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  previewOrders: PropTypes.shape({}),
  notifying_action_groups: PropTypes.arrayOf(PropTypes.string).isRequired,
  coldstoreSendEmailError: PropTypes.string,
  clearColdStoreEmailModal: PropTypes.func.isRequired,
  isColdstoreEmailSent: PropTypes.bool.isRequired,
  internalPoLabel: PropTypes.string.isRequired,
  user: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
};
