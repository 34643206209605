import { Table, Text, Button, VStack, Image, Center, Group, Box } from '@chakra-ui/react';
import { get, orderBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Datetime from 'react-datetime';
import NoDataImageSrc from '../../../assets/no-data.svg';
import {
  DialogRoot,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogCloseTrigger,
} from '@/components/ui/dialog';
import { Alert } from '../../ui/alert';

class ConfirmYourSelectionModal extends Component {
  static propTypes = {
    isVisible: PropTypes.bool,
    orders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        internal_po_number: PropTypes.string,
        grinder_po_number: PropTypes.string,
        delivery_date: PropTypes.string,
        used_by_date: PropTypes.string,
        cl_point: PropTypes.string,
        price: PropTypes.number,
        coldstore: PropTypes.string,
        lines: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            used_by_date: PropTypes.string,
            input_product: PropTypes.shape({
              cl: PropTypes.string,
            }),
            sell_price_per_unit: PropTypes.number,
          })
        ),
        coldstore_details: PropTypes.shape({
          cold_store: PropTypes.shape({
            location_name: PropTypes.string,
          }),
        }),
      })
    ),
    orderDeliveryDates: PropTypes.instanceOf(Object),
    inputProducts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        // Add other properties as needed
      })
    ),
    onCloseModal: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    isSubmitting: false,
    isConfirmed: false,
  };

  componentDidUpdate(prevProps) {
    const { orders } = this.props;
    const { isSubmitting } = this.state;
    if (isSubmitting && prevProps.orders !== orders) {
      this.setState({
        isSubmitting: false,
        isConfirmed: true,
      });
    }
  }

  handleConfirm = () => {
    const { orders, orderDeliveryDates, onSubmit } = this.props;
    const updatedOrders = orders
      .map(order => ({
        ...order,
        delivery_date: orderDeliveryDates[order.id],
      }))
      .sort((a, b) => new Date(a.delivery_date) - new Date(b.delivery_date));
    this.setState({ isSubmitting: true }, () => {
      onSubmit(updatedOrders);
    });
  };

  renderDeliveryDate = order => {
    return (
      <div>
        <input {...order} />
      </div>
    );
  };

  getColumns = () => [
    {
      title: 'PO#',
      dataIndex: 'internal_po_number',
    },
    {
      title: 'Grinder PO#',
      dataIndex: 'grinder_po_number',
    },
    {
      title: 'Delivery Date',
      dataIndex: 'delivery_date',
      render: order => (
        <Datetime
          id="delivery_date"
          inputProps={{
            placeholder: '',
            className: 'form-control',
            style: { left: 0 },
            readOnly: true,
          }}
          closeOnSelect
          value={
            this.props.orderDeliveryDates[order.id] && moment(this.props.orderDeliveryDates[order.id], 'YYYY-MM-DD')
          }
          renderDeliveryDate={ordr => this.renderDeliveryDate(ordr)}
          onChange={value => {
            try {
              this.props.onDateChange(order.id, value);
            } catch (e) {
              this.props.onDateChange(order.id, null);
            }
          }}
          timeFormat={false}
          dateFormat="MM-DD-YYYY"
        />
      ),
    },
    {
      title: 'Use by Date',
      dataIndex: 'used_by_date',
    },
    {
      title: 'CL',
      dataIndex: 'cl_point',
    },
    {
      title: 'Grinder Delivered Price',
      dataIndex: 'price',
    },
    {
      title: 'Coldstore',
      dataIndex: 'coldstore',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: order => (
        <button
          key={order.id}
          type="button"
          className="fa fa-trash"
          onClick={() => {
            const updatedOrderIdList = this.props.orders
              .filter(selectedOrder => selectedOrder.id !== order.id)
              .map(filteredOrder => filteredOrder.id);
            this.props.setUpdatedOrderIds(updatedOrderIdList);
          }}
          style={{ cursor: 'pointer', border: 'none', backgroundColor: 'transparent' }}
        />
      ),
    },
  ];

  getOrderDeliveryDates = order => get(this.props.orderDeliveryDates, order.id);

  getDataSource = () => {
    const lines = [];
    const orders = orderBy(this.props.orders, order => this.getOrderDeliveryDates(order));
    orders.forEach(order => {
      order.lines.forEach(line => {
        const usedByDate = line.used_by_date && moment(line.used_by_date, 'YYYY-MM-DD');
        const formattedUsedByDate = usedByDate && usedByDate.format('MM-DD-YYYY');
        lines.push({
          key: line.id,
          internal_po_number: order.internal_po_number,
          grinder_po_number: order.grinder_po_number,
          delivery_date: order,
          used_by_date: formattedUsedByDate || '',
          cl_point: get(line, 'input_product.cl'),
          price: line.sell_price_per_unit ? line.sell_price_per_unit.toFixed(4) : 0,
          coldstore: order.coldstore_details ? order.coldstore_details.cold_store.location_name : null,
          actions: order,
        });
      });
    });
    return lines;
  };

  renderConfirmation = () => {
    const columns = this.getColumns();
    const dataSource = this.getDataSource();

    return (
      <VStack align="stretch" gap="24px" paddingX="32px">
        <Text as="p">
          The following orders will be sent to their respective grinder(s). Click &apos;Confirm&apos; to proceed.
        </Text>
        <Box
          overflowX="visible"
          overflowY="visible"
          borderWidth="1px"
          borderRadius="2px"
          maxHeight="400px"
          style={{ overflowY: 'auto' }}
        >
          <Table.Root size="sm">
            <Table.Header bg="gray.100">
              <Table.Row>
                {columns.map(col => (
                  <Table.ColumnHeader key={col.dataIndex} color="black" fontSize="12px" padding="10px 8px">
                    {col.title.split(' ').map((text, index) => (
                      <>
                        {text}
                        {col.title.split(' ').length - 1 !== index && <br />}
                      </>
                    ))}
                  </Table.ColumnHeader>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body backgroundColor="white">
              {dataSource.length > 0 ? (
                dataSource.map(data => (
                  <Table.Row key={data.key}>
                    <Table.Cell>{data.internal_po_number}</Table.Cell>
                    <Table.Cell>{data.grinder_po_number}</Table.Cell>
                    <Table.Cell>
                      <Datetime
                        id="delivery_date"
                        inputProps={{
                          placeholder: '',
                          className: 'form-control',
                          style: { left: 0, width: '220px' },
                          readOnly: true,
                        }}
                        closeOnSelect
                        value={
                          this.props.orderDeliveryDates[data.delivery_date.id] &&
                          moment(this.props.orderDeliveryDates[data.delivery_date.id], 'YYYY-MM-DD')
                        }
                        renderDeliveryDate={() => this.renderDeliveryDate(data.delivery_date)}
                        onChange={value => {
                          try {
                            this.props.onDateChange(data.delivery_date.id, value);
                          } catch (e) {
                            this.props.onDateChange(data.delivery_date.id, null);
                          }
                        }}
                        timeFormat={false}
                        dateFormat="MM-DD-YYYY"
                      />
                    </Table.Cell>
                    <Table.Cell>{data.used_by_date}</Table.Cell>
                    <Table.Cell>{data.cl_point}</Table.Cell>
                    <Table.Cell>{data.price}</Table.Cell>
                    <Table.Cell>{data.coldstore}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <button
                        type="button"
                        key={data.actions.id}
                        className="fa fa-trash"
                        onClick={() => {
                          const updatedOrderIdList = this.props.orders
                            .filter(selectedOrder => selectedOrder.id !== data.actions.id)
                            .map(filteredOrder => filteredOrder.id);
                          this.props.setUpdatedOrderIds(updatedOrderIdList);
                        }}
                        style={{ cursor: 'pointer', border: 'none', backgroundColor: 'transparent' }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row backgroundColor="card.default">
                  <Table.Cell colSpan={columns.length}>
                    <Center height="102px">
                      <Image src={NoDataImageSrc} alt="no data" />
                    </Center>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table.Root>
        </Box>
      </VStack>
    );
  };

  renderResult = () => (
    <Alert
      status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      bgColor="inherit"
      title="Thanks!"
    >
      <Text as="p">The selected load(s) can now be scheduled for delivery.</Text>
      <Text as="p">An email has been sent to internal users and respective grinders.</Text>
    </Alert>
  );

  handleExit = () => {
    const { onCloseModal } = this.props;
    this.setState({
      isLoading: false,
      isConfirmed: false,
    });
    onCloseModal();
  };

  render() {
    const { isVisible, orders, goToScheduleTab, onCloseModal } = this.props;
    const { isSubmitting, isConfirmed } = this.state;
    return (
      <DialogRoot open={isVisible} onClose={onCloseModal}>
        <DialogContent maxWidth="860px">
          <DialogHeader borderBottom="1px" borderColor="gray.200" paddingLeft="48px" marginBottom="17px">
            <Text as="p" fontSize="20px" fontWeight="bold">
              Confirm Your Selection
            </Text>
            <DialogCloseTrigger asChild onClick={onCloseModal} marginTop="8px" />
          </DialogHeader>
          <DialogBody paddingX={0}>{isConfirmed ? this.renderResult() : this.renderConfirmation()}</DialogBody>
          <DialogFooter borderTopWidth="1px" marginTop="48px">
            <Group gap="8px">
              <Button
                variant={this.getDataSource().length > 0 ? 'outline' : 'solid'}
                colorScheme={this.getDataSource().length < 1 ? 'actionPrimary' : null}
                height="40px"
                width="116px"
                onClick={onCloseModal}
              >
                Close
              </Button>
              <Button
                colorScheme="actionPrimary"
                height="40px"
                width="112px"
                onClick={this.handleConfirm}
                loading={isSubmitting}
                hidden={isConfirmed || orders.length === 0}
              >
                Confirm
              </Button>
              <Button
                colorScheme="actionPrimary"
                height="40px"
                width="112px"
                onClick={() => {
                  this.handleExit();
                  goToScheduleTab();
                }}
                hidden={!isConfirmed}
              >
                Go to Schedule
              </Button>
            </Group>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    );
  }
}

export default ConfirmYourSelectionModal;
