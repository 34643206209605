import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import moment from 'moment';
import { Input } from '@chakra-ui/react';
import { FaCalendar } from 'react-icons/fa';

import Constants from '../../Constants';
import { InputGroup } from '../ui/input-group';

function DatePicker(props) {
  const {
    closeOnSelect,
    dateFormat,
    timeFormat,
    id,
    inputProps,
    onChange,
    value,
    timeConstraints,
    isValidDate,
    onBlur,
    ...rest
  } = props;

  const dateFormatStr = dateFormat || 'YYYY-MM-DD';

  return (
    <Datetime
      {...{
        id,
        inputProps: {
          borderColor: '#ccc',
          backgroundColor: '#fff',
          ...inputProps,
        },
        renderInput: (finalInputProps, openCalendar) => (
          <CalendarInput {...{ ...finalInputProps, onClick: openCalendar }} />
        ),
        closeOnSelect: closeOnSelect || true,
        value,
        onChange: e => {
          const val = e && typeof e === 'string' && e.length > 9 ? moment(e, Constants.DEFAULT_READ_DATE_FORMAT) : e;
          // eslint-disable-next-line no-underscore-dangle
          const formattedVal = val?._isValid
            ? val.format(typeof timeFormat === 'string' ? timeFormat : dateFormatStr)
            : e;
          return onChange(formattedVal);
        },
        dateFormat: dateFormatStr,
        timeFormat: timeFormat || false,
        timeConstraints,
        isValidDate: isValidDate && typeof isValidDate === 'function' ? isValidDate : () => true,
        onBlur: onBlur && typeof onBlur === 'function' ? onBlur : () => true,
        placeholderText: 'Select',
        ...rest,
      }}
    />
  );
}

DatePicker.propTypes = {
  closeOnSelect: PropTypes.bool,
  dateFormat: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputProps: PropTypes.shape({
    borderColor: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isValidDate: PropTypes.func,
  timeFormat: PropTypes.string,
  timeConstraints: PropTypes.shape({
    hours: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const CalendarInput = ({ borderColor, backgroundColor, height, value, onClick, inputRightElement, ...rest }) => (
  <InputGroup
    width="100%"
    borderColor={borderColor}
    backgroundColor={backgroundColor}
    onClick={onClick}
    {...(inputRightElement && {
      endElement: <FaCalendar />,
    })}
  >
    <Input
      fontWeight="500"
      value={value}
      _focus={{ outline: 'none' }}
      style={{ paddingLeft: 10, paddingRight: 20 }}
      {...rest}
    />
  </InputGroup>
);

CalendarInput.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputRightElement: PropTypes.node,
};

export default DatePicker;
